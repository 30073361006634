import firebase from 'firebase/app';
import 'firebase/messaging';
import deviceManager from "./DeviceManager";

const firebaseConfig = {
    apiKey: "AIzaSyBU-0L3Z6_jI_Bgla9eiFIjzwzyHajAXXw",
    authDomain: "routa-metsahallitus-messaging.firebaseapp.com",
    projectId: "routa-metsahallitus-messaging",
    storageBucket: "routa-metsahallitus-messaging.appspot.com",
    messagingSenderId: "546684273851",
    appId: "1:546684273851:web:d7e0122b9a8c8b2c51d59e",
    measurementId: "G-EJERMXE5WK"
};

const vapidKey = 'BHsKvfHK8-k4NdbuH3J63qncbqfRnzuKWClsetPZhI1d7noFTPKzl8WxrL_ZGHhrbvQPeVftWezqKRjLXuUsXdw';
const firebaseApp = firebase.initializeApp(firebaseConfig);

let token;

function tryParse(json) {
    try {
        return JSON.parse(json);
    } catch (e) {
        return json;
    }
}

function parseMsg(msg) {
    const ret = msg;
    ret.data.body = tryParse(ret.data.body);
    return ret;
}

export async function register(restApi, messageHandler) {
    const msg = firebase.messaging(firebaseApp);
    token = await msg.getToken({vapidKey});
    await deviceManager.register(restApi, token);
    if (messageHandler instanceof Function) {
        try {
            const bgNotification = new BroadcastChannel('bgNotification');
            bgNotification.onmessage = evt => messageHandler(parseMsg(evt.data));
            msg.onMessage(evt => messageHandler(parseMsg({...evt, action: 'fgmessage'})));
        } catch {
            // Ignore - Notifications could not be initialized (e.g. iOS)
            // eslint-disable-next-line
            console.warn('Notifications not active')
        }
    }
}

export async function unregister(restApi) {
    if (token != null) {
        try {
            await deviceManager.unregister(restApi, token);
        } catch (ex) {
            // eslint-disable-next-line
            console.error(ex)
        } finally {
            try {
                await firebase.messaging().deleteToken();
            } catch (e) {
                // eslint-disable-next-line
                console.error(e)
            }
        }
    }
}

/**
 * @param restApi mixin
 * @param {string} topic to send the notification to
 * @param {string} title of the notification
 * @param {string} message
 * @param {string|null} icon
 */
export async function sendNotification(restApi, topic, title, message, icon = null) {
    await new Promise((resolve, reject) =>
        restApi.restAdd(
            `${restApi.notification}/send`,
            {
                topic: topic,
                title: title,
                message: message,
                icon: icon,
            },
            resolve,
            reject
        )
    )
}
